// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  appName: 'DEV Campaign Manager',
  gatewayEndpoint: 'https://gateway.dev.storytagger.com/api',
  // gatewayEndpoint: 'http://localhost:8081/api',
  userCookie: 'localStoryUser',
  currentUser: 'currentUser',
  activeOrganization: 'activeOrganization',
  STRIPE_PK: 'pk_test_gNy54LwD6spuKkg2X648riTU00geiilaOZ',
  platform: 'admin',
  intercom_app_id: 'nzs86h7w',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
