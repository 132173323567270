import {HttpClient, HttpParams} from '@angular/common/http';
import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {CustomEncoder} from '../../_classes/custom-encoder';
import { UsersService } from '../users/users.service';
import {SubtitleOption} from "../../_models/subtitle-options";
import {MinutesOptions} from "../../_models/minutes-options";


@Injectable({
  providedIn: 'root'
})
export class OrganizationsService {
  private apiUrl = environment.gatewayEndpoint;

  constructor(
    private http: HttpClient,
    private usersService: UsersService
  ) { }

  public minutesOptions: MinutesOptions[] = [];

  // GET List Engagements
  list(_params = {}): Observable<any> {
    const params = new HttpParams({
      encoder: new CustomEncoder(),
      fromObject: _params
    });
    return this.http.get<any>(`${this.apiUrl}/organizations`, { params });
  }

  // GET Show Organization
  get(orgId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/organizations/${orgId}`);
  }

  // GET Show Organization
  getMin(orgId): Observable<any> {
    const webhookUrl = this.apiUrl.substring(0, this.apiUrl.lastIndexOf("/") );

    return this.http.get<any>(`${webhookUrl}/webhooks/organizations-min/${orgId}`);
  }

  // PUT Update Organization
  update(data): Observable<any> {
    return this.http.put<any>(`${this.apiUrl}/organizations/${data.id}/manage`, data).pipe(
      map((model) => {
        return model;
      })
    );
  }

  // POST Create Organization
  store(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/organizations`, data).pipe(
      map((model) => {
        return model;
      })
    );
  }

  // GET Get Statuses
  getStatuses(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/organizations/statuses`);
  }

  getPlans(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/organizations/plans`);
  }

  // POST Update Statuses
  updateStatuses(data): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/organizations/statuses`, data).pipe(
      map((model) => {
        return model;
      })
    );
  }

  // List Domains
  listDomains(organizationId): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/organizations/${organizationId}/domains`);
  }


  // Add Domain
  addDomain(data, organizationId): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/organizations/${organizationId}/domains`, data).pipe(
      map((model) => {
        return model;
      })
    );
  }

  // Delete Domain
  deleteDomain(organizationId, domainId): Observable<any> {
    return this.http
      .delete<any>(`${this.apiUrl}/organizations/${organizationId}/domains/${domainId}`)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  // Set Domain Validation
  setDomainValidation(data, organizationId): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/organizations/${organizationId}/set-domain-validation`, data).pipe(
      map((model) => {
        return model;
      })
    );
  }

  getMinutesOptions(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/organizations/get-minutes-options`).pipe(
      map((model) => {
        return model;
      })
    );
  }

  updateSubtitleAvailableMinutes(subtitle_available_seconds) {
    const lsOrg = JSON.parse(localStorage.getItem(environment.activeOrganization));
    lsOrg.subtitle_available_seconds = subtitle_available_seconds;
    localStorage.setItem(environment.activeOrganization, JSON.stringify(lsOrg));
    this.usersService.setActiveOrganizationValue(lsOrg);

    let currentUser = JSON.parse(localStorage.getItem(environment.currentUser));
    if (currentUser.organizations) {
      for (const org in currentUser.organizations) {
        if (currentUser.organizations[org].id == lsOrg.id) {
          currentUser.organizations[org].subtitle_available_seconds = subtitle_available_seconds;
          localStorage.setItem(environment.currentUser, JSON.stringify(currentUser));
          break;
        }
      }
    }
  }

  getSubtitleNeededTimeByStoriesAndLanguage(subtitleOption: SubtitleOption) {
    const params = {};
    const storiesPrepared = [];
    const stories = subtitleOption.stories;
    stories.forEach((story, idx) => {
      storiesPrepared.push({'id' : story.id, 'video_duration': story.total_video_duration});
    });
    params['stories[]'] = JSON.stringify(storiesPrepared);
    params['subtitle_language'] = subtitleOption.language;
    params['download_type'] = subtitleOption.video_type;
    return this.http.get<any>(`${this.apiUrl}/stories/download/check-subtitles-duration`, {params}).pipe(
      map((model) => {
        return model;
      })
    );
  }
}
