import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CustomToastService {

constructor(private toastr: ToastrService) { }

showError(message: string) {
  const config = {};
  // config['toastClass'] = ['custom-toast error-custom-toast'];
  config['toastClass'] = ['custom-toast warning-custom-toast'];
  config['timeOut'] = 7000;
  config['positionClass'] = 'toast-top-right';
  this.toastr.error(message, null, config);
}

showWarning(message: string) {
  const config = {};
  config['toastClass'] = ['custom-toast warning-custom-toast'];
  config['timeOut'] = 7000;
  config['positionClass'] = 'toast-top-right';
  this.toastr.warning(message, null, config);
}

showSuccess(message: string) {
  const config = {};
  config['toastClass'] = ['custom-toast success-custom-toast'];
  config['timeOut'] = 5000;
  config['positionClass'] = 'toast-top-right';
  this.toastr.success(message, null, config);
}

showInfo(message: string) {
  const config = {};
  config['toastClass'] = ['custom-toast info-custom-toast'];
  config['timeOut'] = 5000;
  config['positionClass'] = 'toast-top-right';
  this.toastr.info(message, null, config);
}

}
