import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { loadStripe, Stripe, StripeError } from '@stripe/stripe-js';
import { from, Observable } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import { BuySubtitleMinutes } from '../../_models/buy-subtitle-minutes';
import { OrganizationsService } from '../organizations/organizations.service';
import {ActivatedRoute} from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class StripeCheckoutService {
  stripe$: Observable<Stripe> = from(loadStripe(environment.STRIPE_PK));
  private apiUrl = environment.gatewayEndpoint;

  constructor(
    private http: HttpClient,
    protected organizationsService: OrganizationsService,
    protected route: ActivatedRoute
  ) {
  }

  getSession(buySubtitleMinutes: BuySubtitleMinutes): Observable<string> {
    return this.http.post<any>(`${this.apiUrl}/stripe/buy-minutes`, buySubtitleMinutes).pipe(
      map((model) => {
        return model.sessionId;
      })
    );
  }

  redirectToCheckout(sessionId: string)
    : Observable<never | { error: StripeError }> {
    return this.stripe$.pipe(
      mergeMap((stripe: Stripe) =>
         stripe.redirectToCheckout({ sessionId })
      )
    );
  }

  goToCheckout(buySubtitleMinutes: BuySubtitleMinutes) {
    return this.getSession(buySubtitleMinutes).pipe(
      mergeMap((sessionId: string) =>
         this.redirectToCheckout(sessionId))
    );
  }

  confirmPayment(stripeSessionId): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/stripe/confirm-payment`, {session_id : stripeSessionId}).pipe(
      map((model) => {
        return model;
      })
    );
  }

  getStripeSessionId() {
    return  this.route.snapshot.queryParamMap.get("session_id");
  }

  getStripeStoryIdsSelected() {
    let ids = this.route.snapshot.queryParamMap.get("story_ids");
    if (ids) {
      return ids.split(',');
    }
    return [];
  }
}
