import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { LoadingSpinnerService } from '../_services/loading-spinner/loading-spinner.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {
  constructor(private loading: LoadingSpinnerService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    this.loading.setLoading(true, request.url);
    return next
      .handle(request)

      .pipe(
        catchError((err) => {
          // if (err.status === 401 || err.status === 403) {}
          this.loading.setLoading(false, request.url);

          return throwError(err);
        })
      )
      .pipe(
        map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            this.loading.setLoading(false, request.url);
          }
          return evt;
        })
      ).pipe(
        finalize(() => {
          this.loading.setLoading(false, request.url);
        })
      );
  }
}
