import {EventEmitter, Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {Organization} from '../../_models/organization';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UsersService {
  private apiUrl = environment.gatewayEndpoint;
  public activeOrganization: Observable<any>;
  private activeOrganizationSubject: BehaviorSubject<Organization>;
  public availableOrganizations: Organization[];
  public headerSubtitleSubject: BehaviorSubject<string>;
  public userUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private http: HttpClient) {
    this.activeOrganizationSubject = new BehaviorSubject<Organization>(
      Object.assign(new Organization(), JSON.parse(localStorage.getItem(environment.activeOrganization)))
    );
    this.activeOrganization = this.activeOrganizationSubject.asObservable();
    this.headerSubtitleSubject = new BehaviorSubject<string>('');
  }

  setActiveOrganizationValue(activeOrg: Organization, notify: boolean = true) {
    if (activeOrg && activeOrg.id) {
      localStorage.setItem(
        environment.activeOrganization,
        JSON.stringify(activeOrg)
      );
      if (notify) {
        this.activeOrganizationSubject.next(activeOrg);
      }
    } else {
      localStorage.removeItem(environment.activeOrganization);
    }
  }

  public get activeOrganizationValue() {
    return this.activeOrganizationSubject.value;
  }

  // GET Get Own Profile
  profile(): Observable<any> {
    return this.http.get<any>(`${this.apiUrl}/users/me`).pipe(
      map((model) => {
        this.userUpdated.emit(model);
        return model;
      })
    );
  }

  getUsersImportTemplate(): Observable<any> {
    const options = {
      responseType: 'blob' as 'json',
    };
    return this.http.get<any>(`${this.apiUrl}/users/get-import-template`, options);
  }

  getAllUserOrganizations() {
    if (this.availableOrganizations) {
      return this.availableOrganizations;
    }
    let currentUser = JSON.parse(localStorage.getItem(environment.currentUser));
    this.availableOrganizations = currentUser.organizations;
    return currentUser.organizations;
  }

  updateUserOrganizationsInLocalStorage(organizations) {
    let currentUser = JSON.parse(localStorage.getItem(environment.currentUser));
    currentUser.organizations = organizations;
    localStorage.setItem(environment.currentUser, JSON.stringify(currentUser));
  }

  resendEmailConfirmation(email: string): Promise<any> {
    return this.http.post(`${this.apiUrl}/resend-email-confirmation`, {email: email}).toPromise();
  }
}
