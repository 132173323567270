import {ResetPassword} from '../../_models/reset-password';
import {EventEmitter, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';
import {UsersService} from '../users/users.service';
import {Organization} from '../../_models/organization';
import {CookieService} from '../cookie/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser: Observable<any>;
  public redirectUrl: string;
  public needValidateTwoFactorLsFlagName = 'tflfn';

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'x-app-platform': 'manager',
    }),
  };

  private currentUserSubject: BehaviorSubject<any>;
  private apiUrl = environment.gatewayEndpoint;
  private userOrganizations: Organization[];

  constructor(
    private http: HttpClient,
    private usersService: UsersService,
    public cookieService: CookieService
  ) {
    this.currentUserSubject = new BehaviorSubject<any>(
      JSON.parse(localStorage.getItem(environment.currentUser))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  loginUser(data) {
    return this.http.post(`${this.apiUrl}/login`, data, this.httpOptions)
      .pipe(this.saveUser());
  }

  getOktaLoginUrl(email) {
    return this.http.get(`${this.apiUrl}/get-sso-login-url?email=${email}`, this.httpOptions);
  }

  loginOkta(code, state) {
    return this.http.get(`${this.apiUrl}/login-with-sso?code=${code}&state=${state}`, this.httpOptions)
      .pipe(this.saveUser());
  }

  private saveUser() {
    return map((model) => {
      localStorage.setItem(environment.currentUser, JSON.stringify(model));
      this.currentUserSubject.next(model);
      this.usersService.setActiveOrganizationValue(model['organizations'][0]);
      this.usersService.userUpdated.emit(model);
      return model;
    });
  }

  isAuthenticated() {
    if (this.currentUserValue) {
      return true;
    }
    return false;
  }

  logout() {
    localStorage.removeItem(environment.currentUser);
    localStorage.removeItem(environment.activeOrganization);
    this.setLsFlagRedirectTwoFactor(0);

    this.currentUserSubject.next(null);
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.post<any>(`${this.apiUrl}/reset-password`, resetPassword, this.httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  requestResetPassword(data) {
    return this.http.post<any>(`${this.apiUrl}/reset-password/request`, data, this.httpOptions)
      .pipe(
        map((model) => {
          return model;
        })
      );
  }

  confirmAccount(data) {
    return this.http.post<any>(`${this.apiUrl}/confirm-account`, data, this.httpOptions)
      .pipe(
        map((model) => {
          if (model.confirmed) {
            localStorage.setItem(environment.currentUser, JSON.stringify(model));
            this.currentUserSubject.next(model);
          }
          return model;
        })
      );
  }

  register(data) {
    return this.http.post<any>(`${this.apiUrl}/register`, data, this.httpOptions)
      .pipe(
        map((model) => {
          if (model.confirmed) {
            localStorage.setItem(environment.currentUser, JSON.stringify(model));
          }
          this.currentUserSubject.next(model);
          if (model.confirmed && model['organizations']) {
            this.usersService.setActiveOrganizationValue(model['organizations'][0]);
          }
          return model;
        })
      );
  }

  public modelHasRole(user, role: string) {
    if (user.roles) {
      return user.roles.some(x => role.includes(x));
    }
    return false;
  }

  public $hasRole(role: string): boolean {
    if (this.currentUserValue) {
      return this.currentUserValue.roles.some(x => role.includes(x));
    }
    return false;
  }

  public $adminCanAccessActiveOrganization(activeOrganization = null): boolean {
    let role = 'super admin';
    if (this.currentUserValue) {
      if (!this.currentUserValue.roles.some(x => role.includes(x))) {
        return true;
      } else {
        this.userOrganizations = this.usersService.getAllUserOrganizations();
        if (!activeOrganization) {
          activeOrganization = this.usersService.activeOrganizationValue;
        }
        if (this.userOrganizations && this.userOrganizations.length && activeOrganization) {
          let orgIds = [];
          for (let i = 0; i < this.userOrganizations.length; i++) {
            if (this.userOrganizations[i]) {
              orgIds.push(this.userOrganizations[i].id);
            }
          }
          if (orgIds.includes(activeOrganization.id)) {
            return true;
          }
        } else if (!this.userOrganizations || this.userOrganizations.length == 0) {
          localStorage.removeItem(environment.activeOrganization);
        }
      }
    }
    return false;
  }

  public $needValidateTfa(): boolean {
    if (this.$hasRole('super admin')) {
      return false;
    }

    if (this.currentUserValue) {
      //validate ony for curator
      if (!this.$hasRole('curator')) {
        return false;
      }

      //check if 2fa is active and cookie is set
      let tfaCookieValue = this.cookieService.getTfaCookieValue();
      if (localStorage.getItem(this.needValidateTwoFactorLsFlagName) == '1' && !tfaCookieValue) {
        return true;
      }
    }
    return false;
  }

  public setLsFlagRedirectTwoFactor(value) {
    localStorage.setItem(this.needValidateTwoFactorLsFlagName, value);
  }

  public $needRedirectToWelcome(): boolean {

    if (this.$hasRole('curator') || this.$hasRole('super admin')) {
      return false;
    }
    return true;
  }
}
