<div class="header">
    <div class="fl-l">
        <p class="header-title">{{ titleService.getTitle() }}</p>
        <p class="header-subtitle">{{ headerSubtitle }}</p>
    </div>
    <div class="header-action fl-r">
        <!-- <div class="header-action-item">
            <i class="icon-message_icon"></i>
            <div class="header-action-counter">3</div>
        </div>
        <div class="header-action-item">
            <i class="icon-top_alerts"></i>
            <div class="header-action-counter">12</div>
        </div> -->

        <div class="header-org">
            <app-select-organization></app-select-organization>
        </div>
    </div>
    <div class="clear"></div>
</div>

