import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';

import {Observable} from "rxjs";
import {CookieService} from "../_services/cookie/cookie.service";
import {Router} from "@angular/router";
import { map, catchError } from 'rxjs/operators';
import {throwError} from "rxjs";
import {AuthService} from "../_services/auth/auth.service";



@Injectable()
export class TfaInterceptor implements HttpInterceptor {
  constructor(
    public cookieService: CookieService,
    private router: Router,
    private authService: AuthService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let tfaCookieValue = this.cookieService.getTfaCookieValue();
    if (tfaCookieValue) {
      req = req.clone({
        setHeaders: {
          [this.cookieService.getTfaCookieName()] : `${this.cookieService.getTfaCookieValue()}`
        }
      });
    }

    return next.handle(req).pipe(
      catchError((error) => {
        return throwError(error);
      }),
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body && event.body.redirect_tfa_required) {
            //set flag on localstorage to prevent activate routes
            this.authService.setLsFlagRedirectTwoFactor(1);
            this.router.navigate(['/auth/login/step-2']);
          }
        }
        return event;
      })
    );
  }
}
