import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {UsersService} from '../../_services/users/users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  headerSubtitle: string;

  constructor(
    public titleService: Title,
    public usersService: UsersService,
    private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.headerSubtitle = '';
      }
    });
  }

  ngOnInit(): void {
    this.usersService.headerSubtitleSubject.subscribe(value => {
      this.headerSubtitle = value;
    });
  }

}
