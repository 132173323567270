<div class="left-navigation-wrapper">
    <div class="logo" [routerLink]="['/campaigns']">
      <img src="../../../assets/images/ST_icon.svg" />
    </div>

    <div class="left-navigation-elem-wrapper">
      <ul>
        <!-- <li
          [routerLink]="'/xxx'"
          routerLinkActive="active-nav"
        >
        <i class="icon-maindashboard"></i>
        </li> -->

        <li
          [routerLink]="'/campaigns'"
          routerLinkActive="active-nav"
          matTooltip="{{'page-title.list-campaigns' | translate}}"
          [matTooltipPosition]="'right'"
        >
        <i class="icon-main_campaign"></i>
        </li>

        <li
          [routerLink]="'/stories'"
          routerLinkActive="active-nav"
          matTooltip="{{'page-title.stories' | translate}}"
          [matTooltipPosition]="'right'"
        >
        <i class="icon-main_stories_icon"></i>
        </li>

        <li
          [routerLink]="'/topics-library'"
          routerLinkActive="active-nav"
          matTooltip="{{'page-title.list-topics' | translate}}"
          [matTooltipPosition]="'right'"
        >
        <i class="icon-main_topics_library_icon"></i>
        <span class="menu-label">{{'shared.new' | translate}}</span>
        </li>

        <li
          [routerLink]="'/creators'"
          routerLinkActive="active-nav"
          matTooltip="{{'page-title.creators' | translate}}"
          [matTooltipPosition]="'right'"
        >
        <i class="icon-main_users_icon"></i>
        </li>

        <li
          *ngIf="authService.$hasRole('super admin, curator')"
          [routerLink]="
          authService.$hasRole('super admin') ? '/organizations' :
          authService.$hasRole('curator') ? ['/organizations', usersService.activeOrganizationValue.id] : ''"
          routerLinkActive="active-nav"
          matTooltip="{{'page-title.organizations' | translate}}"
          [matTooltipPosition]="'right'"
        >
          <i class="icon-organization_ico"></i>
        </li>


        <li
          (click)="navigateToHelp()"
          matTooltip="{{'page-title.help' | translate}}"
          [matTooltipPosition]="'right'"
        >
        <i class="icon-main_help_icon"></i>
        </li>

      </ul>
    </div>

    <div class="log-out-btn">
        <i (click)="logout()" class="icon-main_signout_icon"></i>
    </div>
  </div>

