import {Injectable} from "@angular/core";
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root',
})
export class CookieService {

  getTfaCookieName() {
    let currentUser = JSON.parse(localStorage.getItem(environment.currentUser));
    let cookieName = 'tfa';
    if (currentUser) {
      cookieName = 'tfa-' + currentUser.id;
    }
    return cookieName;
  }

  setCookie(name: string, val: string, expireDays: number = null) {
    const date = new Date();
    const value = val;

    if (!expireDays) {
      expireDays = 4 * 365;
    }

    // Set expire
    date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+"="+value+"; expires="+date.toUTCString()+"; path=/";
  }

  getCookie(name: string) {
    const value = "; " + document.cookie;
    const parts = value.split("; " + name + "=");

    if (parts.length == 2) {
      return parts.pop().split(";").shift();
    }
  }

  deleteCookie(name: string) {
    const date = new Date();

    // Set it expire in -1 days
    date.setTime(date.getTime() + (-1 * 24 * 60 * 60 * 1000));

    // Set it
    document.cookie = name+"=; expires="+date.toUTCString()+"; path=/";
  }

  getTfaCookieValue() {
    return this.getCookie(this.getTfaCookieName());
  }
}
