import {
  HttpEvent,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CustomToastService } from '../_services/custom-toast/custom-toast.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private customToast: CustomToastService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {

        if (error?.error?.errors) {
          this.iterateObj(error.error.errors);
        } else {
          if (error && error.error && error.error.message) {
            this.customToast.showWarning(error?.error?.message);
          }
        }

        return throwError(error);
      })
    );
  }

  iterateObj(obj = {}) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (typeof obj[key] === 'object') {
          this.iterateObj(obj[key]);
          if (key === 'details') {
            this.customToast.showError(obj[key]);
          }
        }
      }
    }
  }
}
