import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AUTH_ROUTES } from './auth-layout-routes';
import { DEFAULT_ROUTES } from './default-layout-routes';
import { DefaultLayoutComponent } from '../layouts/default-layout/default-layout.component';
import { AuthLayoutComponent } from '../layouts/auth-layout/auth-layout.component';
import { FullBackgroundCenterContentLayoutComponent } from '../layouts/full-background-center-content-layout/full-background-center-content-layout.component';
import { environment } from '../../environments/environment';
import {BrowserCheckGuard} from '../creator/browser-check.guard';

const routes: Routes =
  environment.platform == 'creator'
    ? [
        {
          path: '',
          loadChildren: () =>
            import('../creator/creator.module').then((m) => m.CreatorModule),
          canActivate: [BrowserCheckGuard]
        },
        // {
        //   path: '',
        //   pathMatch: 'full',
        //   redirectTo: 'creator',
        // },
      ]
    : [
        {
          path: '',
          component: DefaultLayoutComponent,
          children: DEFAULT_ROUTES,
        },
        { path: 'auth', component: AuthLayoutComponent, children: AUTH_ROUTES },

        {
          path: 'engagement',
          component: FullBackgroundCenterContentLayoutComponent,
          children: [
            {
              path: '',
              loadChildren: () =>
                import(
                  '../features/protected/engagement-sign-up/engagement-sign-up.module'
                ).then((m) => m.EngagementSignUpModule),
            },
          ],
        },

        // { path: '**', redirectTo: '404' }
      ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
