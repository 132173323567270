import {AuthService} from './../../../_services/auth/auth.service';
import {Organization} from './../../../_models/organization';
import {UsersService} from './../../../_services/users/users.service';
import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-select-organization',
  templateUrl: './select-organization.component.html',
  styleUrls: ['./select-organization.component.scss'],
})
export class SelectOrganizationComponent implements OnInit {
  organizations: Organization[];
  activeOrganization: Organization;

  constructor(
    public usersService: UsersService,
    public authService: AuthService,
    private router: Router) {
  }

  ngOnInit() {
    this.activeOrganization = this.usersService.activeOrganizationValue;
    this.organizationsList();
  }

  organizationsList() {
    this.usersService.profile().subscribe(data => {
      this.organizations = data.organizations.map(organization => Object.assign(new Organization(), organization));
      this.usersService.availableOrganizations = this.organizations;
      this.usersService.updateUserOrganizationsInLocalStorage(this.organizations);

      let needChangeOrg = false;

      let activeOrganizationIndex = 0;
      if (this.organizations && this.organizations.length && this.activeOrganization) {
        let orgIds = [];
        for (let i = 0; i < this.organizations.length; i++) {
          orgIds.push(this.organizations[i].id);
          if (this.organizations[i].id === this.activeOrganization.id) {
            activeOrganizationIndex = i;
          }
        }
        if (!orgIds.includes(this.activeOrganization.id)) {
          needChangeOrg = true;
        } else {
          this.usersService.setActiveOrganizationValue(this.organizations[activeOrganizationIndex]);
        }
      } else if (this.organizations && this.organizations.length && !this.activeOrganization) {
        needChangeOrg = true;
      }

      if (needChangeOrg) {
        this.setActiveOrganization(this.organizations[0]);
        this.router.navigateByUrl('/organizations');
      }
    });
  }

  changeOrganization(e) {
    this.organizationsList();
    this.setActiveOrganization(e.value);
  }

  setActiveOrganization(organization) {
    this.usersService.setActiveOrganizationValue(organization);
    this.router.navigate(['/campaigns']);
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.name === o2.name && o1.id === o2.id;
  }
}


