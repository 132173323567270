import { Component } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-browser-popup',
    template: `
    <div class="popup-overlay">
      <div class="popup-content">
        <h2>Browser not supported</h2>
        <p>Your browser is not currently supported by StoryTagger. Please use Edge, Chrome or Firefox.</p>
<!--        <button (click)="closePopup()">Close</button>-->
      </div>
    </div>
  `,
    styles: [`
    .popup-overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .popup-content {
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      text-align: center;
    }

    button {
      padding: 10px 20px;
    }
  `]
})
export class BrowserPopupComponent {
    constructor(private activeModal: NgbActiveModal) {}
    closePopup() {
        this.activeModal.dismiss();
    }
}
