import { AuthService } from '../../_services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import { UsersService } from 'src/app/_services/users/users.service';
import { OrganizationsService } from '../../_services/organizations/organizations.service';
import { StripeCheckoutService } from 'src/app/_services/stripe/stripe-checkout.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

  constructor(
    public router: Router,
    public authService: AuthService,
    public usersService: UsersService,
    private route: ActivatedRoute,
    private stripeService: StripeCheckoutService,
    private organizationsService: OrganizationsService
  ) { }

  ngOnInit(): void {
    this.checkStripeSessionId();
  }

  logout() {
    this.authService.logout();
    this.router.navigate(['/auth/login']);
  }

  navigateToHelp() {
    window.open('https://help1.storytagger.com', '_blank');
  }

  checkStripeSessionId() {
    const stripeSessionId = this.stripeService.getStripeSessionId();
    if (stripeSessionId) {
      this.stripeService.confirmPayment(stripeSessionId).subscribe(data => {
        this.organizationsService.updateSubtitleAvailableMinutes(data.subtitle_available_seconds);
      });
    }
  }
}
