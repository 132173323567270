import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '../_services/auth/auth.service';
import { environment } from '../../environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const currentUser = this.authService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.token;
        const isApiUrl = request.url.startsWith(environment.gatewayEndpoint);

        let xAppPlatform = 'manager';
        if (environment.platform === 'creator') {
            xAppPlatform = 'creator';
        }

        if (isApiUrl && (isLoggedIn || request.url.includes('api/login') || request.url.endsWith('api/register'))) {
          let headers = request.headers;
          headers = headers.set( 'x-app-platform', xAppPlatform);
          if (isLoggedIn) {
            headers = headers.set( 'Authorization', `Bearer ${currentUser.token}`);
          }
          const cloneReq = request.clone({ headers });
          return next.handle(cloneReq);
        }

        return next.handle(request);
    }
}
