import { ErrorInterceptor } from './_helper/error.interceptor';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './routes/app-routing.module';
import { AppComponent } from './layouts/app/app.component';

import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DefaultLayoutComponent } from './layouts/default-layout/default-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { SharedModule } from './shared/shared.module';
import { JwtInterceptor } from './_helper/jwt.interceptor';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { HttpRequestInterceptor } from './_helper/loading-spinner.interceptor';
import {HeaderComponent} from './layouts/header/header.component';
import {FooterComponent} from './layouts/footer/footer.component';
import {MenuComponent} from './layouts/menu/menu.component';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectOrganizationComponent } from './shared/components/select-organization/select-organization.component';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import {TfaInterceptor} from './_helper/tfa.interceptor';
import {NotAllowedInterceptor} from './_helper/not-allowed.interceptor';
import { FullBackgroundCenterContentLayoutComponent } from './layouts/full-background-center-content-layout/full-background-center-content-layout.component';
import {HoursMinutesSecondsPipe} from './shared/pipes/format-seconds-pipe';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { DragulaModule } from 'ng2-dragula';
import {BrowserCheckGuard} from './creator/browser-check.guard';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
    AuthLayoutComponent,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    SelectOrganizationComponent,
    FullBackgroundCenterContentLayoutComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      }
    }),
    AppRoutingModule,
    NoopAnimationsModule,
    ToastrModule.forRoot(),
    NgbModule,
    MatSelectModule,
    MatTooltipModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    DragulaModule.forRoot()
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: TfaInterceptor, multi: true},
    { provide: HTTP_INTERCEPTORS, useClass: NotAllowedInterceptor, multi: true},
    HoursMinutesSecondsPipe,
    { provide: NG_ENTITY_SERVICE_CONFIG, useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }},
    BrowserCheckGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
