import { Pipe, PipeTransform } from '@angular/core';
/*
 * Transform seconds to H m s format
 * Usage:
 *   value | hoursMinutesSeconds
 * Example:
 *   {{ 6090 | hoursMinutesSeconds}}
 *   formats to: 1h 41m 30s
*/
@Pipe({name: 'hoursMinutesSeconds'})
export class HoursMinutesSecondsPipe implements PipeTransform {
  transform(value: number, clearFormat: boolean = false, clockFormat = false): String {
    value = Number(value);
    let negativ = false;
    if (value < 0) {
      value = value * -1;
      negativ = true;
    }
    let h = Math.floor(value / 3600);
    let m = Math.floor(value % 3600 / 60);
    let s = Math.floor(value % 3600 % 60);

    m = Math.floor(m + 60 * h);

    let mDisplay = m > 0 ? String(m) + (clearFormat ? " min" : "m") : "";
    if (clockFormat) {
      if (m < 10) {
        mDisplay = '0' + String(m);
      } else {
        mDisplay = String(m);
      }
    }


    let sDisplay = s > 0 ? String(s) + (clearFormat ? " sec" : "s") : "";
    if (clockFormat) {
      if (s < 10) {
        sDisplay = '0' + String(s);
      } else {
        sDisplay = String(s);
      }
    }
    if (m === 0 && s === 0 && !clockFormat) {
      sDisplay = "0" + (clearFormat ? " sec" : " s");
    }

    if (clockFormat) {
      return mDisplay + ':' + sDisplay;
    }

    return (negativ ? "-" : "") + mDisplay + " " + sDisplay;
  }
}
