import { CanActivate, Router } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {BrowserPopupComponent} from './browser-popup.component';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BrowserCheckGuard implements CanActivate {
    constructor(
       private modal: NgbModal
    ) {}

    canActivate(): boolean {
        const userAgent = window.navigator.userAgent;
        const isSupportedBrowser = /Chrome|Firefox|Edg/.test(userAgent);

        if (!isSupportedBrowser) {
            this.modal.open(BrowserPopupComponent, { centered: true });
            return true;
        }

        return true;
    }
}
