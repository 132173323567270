<div class="auth-wrapper">

    <div class="auth-left-wrapper">
      <div class="auth-left-inner">
        <div class="logo">
          <img class="fl-l" [routerLink]="['/campaigns']" src="../../../assets/images/logo_storytagger_long.svg" />
        </div>
        <div class="clear"></div>
        
        <div class="auth-left-body">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>

    <div class="auth-right-wrapper">
    </div>
  </div>