import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';

import {Observable} from "rxjs";
import {Router} from "@angular/router";
import { map, catchError } from 'rxjs/operators';
import {throwError} from "rxjs";



@Injectable()
export class NotAllowedInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
  ) {}

  private redirectCnt = 0;


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    return next.handle(req).pipe(
      catchError((error) => {
        if (error.status == 403) {
          if (this.redirectCnt == 0) {
            this.redirectCnt ++;
            this.router.navigate(['/campaigns']);
          }
        }
        return throwError(error);
      }),
      map((event: HttpEvent<any>) => {
        return event;
      })
    );
  }
}
