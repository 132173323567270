import { Routes } from '@angular/router';

export const DEFAULT_ROUTES: Routes = [
  {
    path: '',
    loadChildren: () => import('../features/protected/static/static.module').then(m => m.StaticModule),
  },
  {
    path: 'campaigns',
    loadChildren: () => import('../features/protected/campaigns/campaigns.module').then(m => m.CampaignsModule),
  },
  {
    path: 'topics-library',
    loadChildren: () => import('../features/protected/topics/topics.module').then(m => m.TopicsModule),
  },
  {
    path: 'stories',
    loadChildren: () => import('../features/protected/stories/stories.module').then(m => m.StoriesModule),
  },
  {
    path: 'creators',
    loadChildren: () => import('../features/protected/creators/creators.module').then(m => m.CreatorsModule),
  },
  {
    path: 'organizations',
    loadChildren: () => import('../features/protected/organizations/organizations.module').then(m => m.OrganizationsModule),
  }
];

