import {Dictionary} from './dictionary';
import {Plan} from './plan';
import {CustomPlanDetails} from './custom-plan-details';
import {Branding} from './branding';

export class Organization {
  id: number;
  name: string;
  slug: number;
  plan: Plan;
  custom_plan_details?: CustomPlanDetails;
  // max_seats: number;
  // max_curators_seats: number;
  filled_seats: number;
  domain_validation: boolean;
  contributors: number;
  status_id: number;
  status: Dictionary;
  created_at: Date;
  subtitle_available_seconds: number;
  subtitle_spent_seconds: number;
  tfa_status_id: number;
  tfa_status: Dictionary;
  branding?: Branding;

  get max_live_campaigns(): number {
    return this.custom_plan_details?.max_live_campaigns ?? this.plan.max_live_campaigns;
  }

  get max_campaign_live_topics(): number {
    return this.custom_plan_details?.max_campaign_live_topics ?? this.plan.max_campaign_live_topics;
  }

  get max_creators_per_campaign(): number {
    return this.custom_plan_details?.max_creators_per_campaign ?? this.plan.max_creators_per_campaign;
  }

  get max_creator_seats(): number {
    return this.custom_plan_details?.max_creator_seats ?? this.plan.max_creator_seats;
  }

  get max_curator_seats(): number {
    return this.custom_plan_details?.max_curator_seats ?? this.plan.max_curator_seats;
  }
}
