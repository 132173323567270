import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth/auth.service';

@Component({
  selector: 'app-full-background-center-content-layout',
  templateUrl: './full-background-center-content-layout.component.html',
  styleUrls: ['./full-background-center-content-layout.component.css']
})
export class FullBackgroundCenterContentLayoutComponent implements OnInit {

  isAuth = false;

  constructor(
    private router: Router,
    private authService: AuthService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (this.authService.isAuthenticated()) {
          this.isAuth = true;
        }
    }
    });
  }

  ngOnInit(): void {
  }

  goToStHelp() {
    window.open('https://help.storytagger.com/');
  }

  signOut() {
    this.authService.logout();
    this.isAuth = false;
  }

}
